import * as React from "react";

class Survey extends React.Component {

  componentDidMount() {
    let el = document.createElement("script");
    el.src = "https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgd6APemm2sOAolx7z6u_2FSoZBJQbWzdk9HVLn0D4Ca_2B1oi.js";
    document.body.appendChild(el);
  }

  render() {
    return <div id='smcx-sdk' />
  }
}

export default Survey