import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Survey from "../components/survey";

const SurveyPage = () => (
  <Layout>
    <Seo title="US Growers Eligibility Survey" />
    <article className="article flow">
      <div className="hero-image-wrapper">
        <StaticImage
          className="hero-image"
          src="../images/main-home.jpg"
          width={637}
          height={637}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="a hand full of dirt"
        />
      </div>
      <div className="headline">
        <h1>Farmer Eligibility Survey</h1>
      </div>
      <div className="article-wrapper flow">
        <Survey/>
      </div>
    </article>
  </Layout>
);

export default SurveyPage;
